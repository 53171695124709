<template>
  <div class="w-full flex justify-center">
    <div class="flex justify-center flex-col items-start cursor-default gap-1">
      <VTooltip max-width="300" v-for="benefit in benefits" :key="benefit.text">
        <template #activator="{ props }">
          <div v-bind="props"
            class="inline-flex items-center gap-2 max-w-[270px] border-b-[2px] hover:border-primary hover:text-primary border-dashed	">
            <VIcon size="18" color="primaryAlt" icon="mdi-check-outline" />
            <span v-html="benefit.text" />
          </div>
        </template>
        <span v-html="benefit.tooltip" />
      </VTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{
  highlightId?: string
}>()

const benefits = computed(() => {
  const theHighlightedBenefit = rawBenefits.find(benefit => benefit.id === props.highlightId)

  if (!theHighlightedBenefit) return rawBenefits

  return [
    theHighlightedBenefit,
    ...rawBenefits.filter(benefit => benefit.id !== theHighlightedBenefit.id)
  ]
})

const rawBenefits = [
  {
    id: "deteccao-avancada",
    text: "Detecção <b>avançada</b> de erros",
    tooltip: `Veja marcações avançadas de erros no seu texto, como repetição de palavras, uso de conectivos, avaliação de frases e parágrafos, e muito mais.`,
  },
  {
    id: "comentarios-detalhados", 
    text: "Comentários detalhados",
    tooltip: `Em todas correções você receberá um comentário detalhado explicando o que você fez de certo e errado, e como melhorar.`,
  },
  {
    id: "redacao-guiada",
    text: "Modo <b>Redação Guiada</b>",
    tooltip: `Não sabe como começar sua redação? Use o <b>Modo Redação Guiada</b> e siga um guia passo a passo para escrever sua redação.`,
  },
  {
    id: "metricas-nota-1000",
    text: "Métricas nota 1000",
    tooltip: `Veja como sua redação se compara com as <b>Redações nota 1000</b> das edições passadas do ENEM e saiba o que melhorar.`,
  },
  {
    id: "curso-redacao",
    text: "Curso <b>Redação de Sucesso</b>",
    tooltip: `Aulas práticas e objetivas para você aprender a escrever uma redação sobre qualquer tema do zero.`,
  },
  {
    id: "ideias-redacao",
    text: "Ideias para redação",
    tooltip: `Ideias de repertórios, argumentos, propostas de intervenção e citações para qualquer tema.`,
  },
  {
    id: "correcao-sem-filas",
    text: "Correção sem filas", 
    tooltip: `Corrija suas redações instantaneamente sem fila de espera.`,
  },
];
</script>
